@-webkit-keyframes SHOW-BOX {
    0%   { opacity: 0; }
    100% { opacity: 1 !important; }
}
@-moz-keyframes SHOW-BOX {
    0%   { opacity: 0; }
    100% { opacity: 1 !important; }
}
@-o-keyframes SHOW-BOX {
    0%   { opacity: 0; }
    100% { opacity: 1 !important; }
}
@keyframes SHOW-BOX {
    0%   { opacity: 0; }
    100% { opacity: 1 !important; }
}

.show-box {
    -webkit-animation: SHOW-BOX 2s ease;
    -moz-animation: SHOW-BOX 2s ease;
    -o-animation: SHOW-BOX 2s ease;
    animation: SHOW-BOX 2s ease;
}

@keyframes UNSHOW-BOX {
    0%   { opacity: 1 ; }
    100% { opacity: 0 ; }
}

.unshow-box {
    animation: UNSHOW-BOX 0.2s ease;
}


@-webkit-keyframes SHOW-BUTTON {
    0%   { opacity: 0; }
    50%  { opacity: 0;}
    75%  { opacity: 1;}
    100% { opacity: 1; }
}
@-moz-keyframes SHOW-BUTTON {
    0%   { opacity: 0; }
    50%  { opacity: 0;}
    75%  { opacity: 1;}
    100% { opacity: 1; }
}
@-o-keyframes SHOW-BUTTON {
    0%   { opacity: 0; }
    50%  { opacity: 0;}
    75%  { opacity: 1;}
    100% { opacity: 1; }
}
@keyframes SHOW-BUTTON {
    0%   { opacity: 0; }
    50%  { opacity: 0;}
    75%  { opacity: 1;}
    100% { opacity: 1; }
}
.show-button {
    -webkit-animation: SHOW-BUTTON 10s linear;
    -moz-animation: SHOW-BUTTON 10s linear;
    -o-animation: SHOW-BUTTON 10s linear;
    animation: SHOW-BUTTON 10s linear;
}

@-webkit-keyframes SHOW-LOGO {
    0%   { margin-top: -15em; opacity: 1; }
    0.25% { opacity: 0.75; }
    0.5% { margin-top: 2em; opacity: 0.6;}
    0.6% { margin-top: 0em; opacity: 0.6;}
    100% { opacity: 0.6; }
}
@-moz-keyframes SHOW-LOGO {
    0%   { margin-top: -15em; opacity: 1; }
    0.25% { opacity: 0.75; }
    0.5% { margin-top: 2em; opacity: 0.6;}
    0.6% { margin-top: 0em; opacity: 0.6;}
    100% { opacity: 0.6; }
}
@-o-keyframes SHOW-LOGO {
    0%   { margin-top: -15em; opacity: 1; }
    0.25% { opacity: 0.75; }
    0.5% { margin-top: 2em; opacity: 0.6;}
    0.6% { margin-top: 0em; opacity: 0.6;}
    100% { opacity: 0.6; }
}
@keyframes SHOW-LOGO {
    0%   { margin-top: -15em; opacity: 1; }
    0.25% { opacity: 0.75; }
    0.5% { margin-top: 2em; opacity: 0.6;}
    0.6% { margin-top: 0em; opacity: 0.6;}
    100% { opacity: 0.6; }
}

.show-logo {
    -webkit-animation: SHOW-LOGO 600s linear;
    -moz-animation: SHOW-LOGO 600s linear;
    -o-animation: SHOW-LOGO 600s linear;
    animation: SHOW-LOGO 600s linear;
}

@-webkit-keyframes SHOW-SCOPUL {
    0%   { opacity: 1; }
    0.5% { opacity: 0.6;}
    100% { opacity: 0.6; }
}
@-moz-keyframes SHOW-SCOPUL {
    0%   { opacity: 1; }
    0.5% { opacity: 0.6;}
    100% { opacity: 0.6; }
}
@-o-keyframes SHOW-SCOPUL {
    0%   { opacity: 1; }
    0.5% { opacity: 0.6;}
    100% { opacity: 0.6; }
}
@keyframes SHOW-SCOPUL {
    0%   { opacity: 1; }
    0.5% { opacity: 0.6;}
    100% { opacity: 0.6; }
}

.show-scopul {
    -webkit-animation: SHOW-SCOPUL 600s ease;
    -moz-animation: SHOW-SCOPUL 600s ease;
    -o-animation: SHOW-SCOPUL 600s ease;
    animation: SHOW-SCOPUL 600s ease;
}

.dunga_misto{
    padding: 24px;
    background-position-y: +70px;
    background-position-x: +40px;
    background-size:20rem;
    background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/664131/underline.svg");
    background-repeat: no-repeat;
}
.color-white{
    color: white !important;
}
.color-brown{
    color: #806A50 !important;
}
.color-green{
    color: #889976 !important;
}
.color-yellow{
    color: #D2AB67 !important;
}
.color-blackk{
    color: #424142 !important;
}
.bg-white{
    background-color: white !important;
}
.bg-brown{
    background-color: #806A50 !important;
}
.bg-green{
    background-color: #889976 !important;
}
.bg-yellow{
    background-color: #D2AB67 !important;
}
.bg-blackk{
    background-color: #424142 !important;
}

.cc-nb-changep{
    background-color: white !important ;
    color:  #424142 !important;
}
.cc-nb-okagree{
    background-color: #D2AB67 !important ;
}
.cc-nb-title{
    color: #D2AB67 !important;
}
.cc-nb-text{
    color: white !important;
}
.freeprivacypolicy-com---nb-simple{
    max-width: 100% !important;
    border-top: 5px solid #D2AB67 ;
    background-color: #424142 !important;
    color: white !important;
}
.freeprivacypolicy-com---palette-light.freeprivacypolicy-com---nb{
    color: white !important;
}
.freeprivacypolicy-com---nb-simple{
    color: white !important;
}
.panel-footer{
    background-color: #D2AB67 !important;
    padding: 0.3 !important;
}
.panel-body{
    color: #424142 !important;
    word-break: normal !important;
}
.panel-title{
    color: white !important;
    background-color: #424142 !important;
}
.close{
    opacity: 1;
    background-color: #D2AB67 !important;
    color: white !important;
}

.landing_logo{
    background-color: white;
    border-radius: 3rem;
    max-width: 80vw;
    width: 500px;
}
.fs-5 {
    font-size: 1.5rem !important;
    line-height: 1 !important;
}
.fs-4 {
    font-size: 2rem !important;
}
.fs-6 {
    font-size: 1.5rem !important;
}
label {
    font-size: 1.3rem;
}
.panel-title{
    word-break: inherit !important;
}
.pure-modal .panel-footer {
    padding: 12px 15px 12px 15px !important;
}
.panel-footer {
    word-break: normal !important;
    padding: 12px 15px!important;
}
html, body {
    max-width: 100%;
    overflow-x: hidden;
}
.show-more-button{
    text-decoration: none !important;
    border: none;
    border-radius: 0 0 3em 3em;
}
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }